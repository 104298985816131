// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-jsx": () => import("./../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-articles-jsx": () => import("./../src/templates/articles.jsx" /* webpackChunkName: "component---src-templates-articles-jsx" */),
  "component---src-templates-doctor-jsx": () => import("./../src/templates/doctor.jsx" /* webpackChunkName: "component---src-templates-doctor-jsx" */),
  "component---src-templates-doctors-jsx": () => import("./../src/templates/doctors.jsx" /* webpackChunkName: "component---src-templates-doctors-jsx" */),
  "component---src-templates-event-jsx": () => import("./../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-events-jsx": () => import("./../src/templates/events.jsx" /* webpackChunkName: "component---src-templates-events-jsx" */),
  "component---src-templates-home-jsx": () => import("./../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-links-jsx": () => import("./../src/templates/links.jsx" /* webpackChunkName: "component---src-templates-links-jsx" */),
  "component---src-templates-service-jsx": () => import("./../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-templates-services-jsx": () => import("./../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-templates-topic-jsx": () => import("./../src/templates/topic.jsx" /* webpackChunkName: "component---src-templates-topic-jsx" */),
  "component---src-templates-topics-jsx": () => import("./../src/templates/topics.jsx" /* webpackChunkName: "component---src-templates-topics-jsx" */),
  "component---src-templates-treatment-jsx": () => import("./../src/templates/treatment.jsx" /* webpackChunkName: "component---src-templates-treatment-jsx" */),
  "component---src-templates-treatments-jsx": () => import("./../src/templates/treatments.jsx" /* webpackChunkName: "component---src-templates-treatments-jsx" */)
}

