export default {
  fonts: {
    body: "Montserrat",
    heading: "Cormorant",
    monospace: "Menlo, monospace",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
  },
  colors: {
    background: "#fff",
    primary: "#002fa7",
    secondary: "#ff6347",
  },
  breakpoints: ["600px", "960px", "1280px", "1920px", "2560px"],
  text: {
    default: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: ["16px", "16px", "20px"],
      //letterSpacing: 'heading',
    },
    footer: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: ["16px", "16px", "16px"],
      color: "white",
    },
    footerHeader: {
      fontFamily: "body",
      fontWeight: "bold",
      lineHeight: "body",
      fontSize: ["16px", "16px", "16px"],
      color: "white",
    },
    homeTitle: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: ["40px", "40px", "60px"],
      color: "white",
      //letterSpacing: 'heading',
      //fontSize: [ 5, 6, 7 ],
    },
    pagesTitle: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: ["35px", "35px", "50px"],
      color: "white",
      //letterSpacing: 'heading',
      //fontSize: [ 5, 6, 7 ],
    },
    paragraphTitle: {
      fontFamily: "body",
      fontWeight: "bold",
      lineHeight: "heading",
      fontSize: ["28px", "28px", "35px"],
      color: "white",
      //letterSpacing: 'heading',
      //fontSize: [ 5, 6, 7 ],
    },
    paragraphTitle2: {
      fontFamily: "body",
      fontWeight: "bold",
      lineHeight: "heading",
      fontSize: ["22px", "22px", "27px"],
      color: "white",
      //letterSpacing: 'heading',
      //fontSize: [ 5, 6, 7 ],
    },
    subTitle: {
      fontFamily: "body",
      fontWeight: "body",
      fontSize: ["18px", "18px", "22px"],
      fontStyle: "italic",
    },
    note: {
      fontFamily: "body",
      fontWeight: "body",
      fontSize: ["13px", "13px", "17px"],
      //fontStyle: "italic",
    },
    pageTitle: {
      textTransform: "uppercase",
      letterSpacing: "caps",
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        bg: "rgba(224, 224, 224, 0.2)",
      },
    },
    roundedGray: {
      cursor: "pointer",
      outline: "none",
      bg: "#9E9E9E",
      color: "#424242",
      borderRadius: "50%",
      padding: "6px",
      "&:hover": {
        bg: "#BDBDBD",
      },
    },
    icon: {
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        bg: "rgba(224, 224, 224, 0.2)",
      },
    },
  },
  styles: {
    // the keys used here reference elements in MDX
    root: {
      margin: 0,
      height: "100%",
    },
  },
};
